<template>
	<div class="_modal choose-popup bg-white">
		<div class="_modal__content px-12 py-6 sm-down:px-4">
			<div class="search-input w-full xl:max-w-lg mt-4">
				<div class="input-group">
					<input
						v-model="searchQuery"
						class="bg-white input-with-icon"
						placeholder="Tìm kiếm trung tâm"
						type="text"
						@keyup="searching"
						@keypress.enter="search"
					/>
					<div class="input-icon absolute top-1/2">
						<DaIcon
							class="h-4 w-4 text-neutral-400"
							name="das--search"
						/>
					</div>
				</div>
			</div>
			<div
				v-for="org in orgList"
				:key="org.org_id"
				class="flex justify-between items-start mt-8 flex-wrap gap-4"
			>
				<div class="flex items-start">
					<div
						:class="{ 'opacity-30': !org.is_active_org }"
						class="mr-4 md:mr-6"
					>
						<img
							v-if="org?.profile_photo?.w200"
							:src="org?.profile_photo?.w200"
							alt=""
							class="shadow-sm inline-block h-16 w-16 rounded-2xl"
							style="max-width: 100%"
						/>
						<span
							v-else
							:style="{
								background: org?.profile_photo?.default?.color,
							}"
							class="
								inline-flex
								items-center
								justify-center
								h-16
								w-16
								rounded-2xl
							"
						>
							<span
								class="
									font-medium
									leading-none
									text-white text-4xl
								"
								>{{
									org?.profile_photo?.default?.initial
								}}</span
							>
						</span>
					</div>
					<div class="flex-1" style="max-width: 150px">
						<div
							:class="{ 'opacity-30': !org.is_active_org }"
							class="font-semibold"
						>
							{{ org.name }}
						</div>
						<div
							:class="{ 'opacity-30': !org.is_active_org }"
							class="truncate"
						>
							{{ org.address }}
						</div>
						<div
							:class="{ 'opacity-30': !org.is_active_org }"
							class="font-semibold"
						>
							{{ org.city }}
						</div>
						<div
							v-if="!org.is_active_org"
							class="flex items-center gap-2 mt-2"
						>
							<svg
								fill="none"
								height="13"
								viewBox="0 0 12 13"
								width="12"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									clip-rule="evenodd"
									d="M2.87497 4.94589V3.14587C2.87497 1.48897 4.27407 0.145996 6 0.145996C7.72593 0.145996 9.12503 1.48907 9.12503 3.14587V4.94589C10.1606 4.94589 11 5.75179 11 6.74592V10.346C11 11.3402 10.1606 12.146 9.12503 12.146H2.87497C1.83945 12.146 1 11.3402 1 10.346V6.74592C1 5.75179 1.83945 4.94589 2.87497 4.94589ZM7.87497 4.94589V3.14587C7.87497 2.15174 7.03552 1.34584 6 1.34584C4.96448 1.34584 4.12503 2.15174 4.12503 3.14587V4.94589H7.87497ZM6 7.34599C6.34518 7.34599 6.62495 7.61458 6.62495 7.94597V9.14602C6.62495 9.4774 6.34518 9.746 6 9.746C5.65482 9.746 5.37505 9.4774 5.37505 9.14602V7.94597C5.37505 7.61458 5.65482 7.34599 6 7.34599Z"
									fill="#E37056"
									fill-rule="evenodd"
								/>
							</svg>
							<span class="text-sm text-neutral-700"
								>Cơ sở đang khoá</span
							>
						</div>
					</div>
				</div>
				<div>
					<button
						class="button-p btn--ghost w-fit px-4 btn--sm"
						type="button"
						@click="changeCenter(org.subdomain)"
					>
						Chọn cơ sở
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Options, setup, Vue } from 'vue-class-component';
import { _var } from '@/enums/Common';
import { useStore } from 'vuex';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';

@Options({
	name: 'ChooseCenter',
	components: { DaIcon },
})
export default class ChooseCenter extends Vue {
	setup = setup(() => {
		const store = useStore();
		return {
			store,
		};
	});

	typing = null;
	searchQuery = '';
	orgList = [];

	get orgs() {
		return this.setup.store.state.profile.orgs;
	}

	get staffOrg() {
		return this.setup.store.state.profile.current_org;
	}

	changeCenter(value) {
		const current = localStorage.getItem(_var.SUBDOMAIN);
		if (current !== value) {
			localStorage.setItem(_var.SUBDOMAIN, value);
			window.location.href = '/';
		}
	}

	created() {
		this.orgList = this.orgs;
	}

	searching() {
		clearInterval(this.typing);
		this.typing = setTimeout(() => {
			this.search();
		}, 0);
	}

	async search() {
		this.orgList = this.orgs.filter((s) => {
			return (
				this.formatTextSearch(s.address).includes(
					this.formatTextSearch(this.searchQuery)
				) ||
				this.formatTextSearch(s.name).includes(
					this.formatTextSearch(this.searchQuery)
				) ||
				this.formatTextSearch(s.subdomain).includes(
					this.formatTextSearch(this.searchQuery)
				)
			);
		});
	}

	formatTextSearch(text) {
		if (!text) {
			return '';
		}
		return text.toLowerCase();
	}
}
</script>
