
import { Options, Vue, setup } from 'vue-class-component';
import SideMenu from '@/components/layouts/sideMenu/SideMenu.vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { useStore } from 'vuex';
import { _ROUTER_NAME } from '@/enums';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import ChooseCenter from '@/components/elements/chooseCenter/ChooseCenter.vue';
import { Watch } from 'vue-property-decorator';

@Options({
	name: 'DefaultLayout',
	components: { ChooseCenter, DaModalFrame, DaIcon, SideMenu },
})
export default class DefaultLayout extends Vue {
	//----------------------- 🤍 Data 🤍 -----------------------//
	sideActive = true;

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		return {
			store,
		};
	});

	mounted() {
		this.sideActive = window.screen.width >= 1024;

		window.addEventListener('resize', () => {
			this.sideActive = window.screen.width >= 1024;
		});
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	setActive(value: boolean) {
		this.sideActive = value;
	}

	modalIsOpen = false;

	openModal() {
		this.modalIsOpen = true;
	}

	logout() {
		localStorage.clear();
		this.$router.replace({ name: _ROUTER_NAME.LOGIN });
	}

	@Watch('$route', { immediate: true, deep: true })
	onRouteChange(value: any) {
		if (
			value &&
			(value?.query?.action === 'edit' ||
				value?.query?.action === 'full-screen')
		) {
			this.setup.store.dispatch('setSideBar', true);
		} else {
			this.setup.store.dispatch('setSideBar', false);
		}
	}

	get version() {
		return process.env.VUE_APP_VERSION;
	}
}
